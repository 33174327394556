.HeaderMain {
    background-color: rgb(225, 236, 247);
    box-sizing: border-box;
    color: rgb(52, 50, 48);
    display: block;
    top: 0px;
    position: static;
    width: 100%;
    height: 85px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 15px;
}

.HeaderButton {
    align-items: center;
    background-color: rgb(255, 221, 113);
    border: solid 4px rgb(52, 50, 48);
    border-radius: 15px;
    box-shadow: rgb(0, 0, 0);
    box-sizing: border-box;
    color: rgb(255, 255, 255);
    font-size: 16px;
    font-weight: 400;
    height: 45px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    min-width: 140px;
    width: 140px;
    box-shadow: rgb(52, 50, 48) -2px 2px 0px 0px;
}
