.IntroductionMain {
    background-color: rgb(225, 236, 247);
    box-sizing: border-box;
    color: rgb(52, 50, 48);
    display: block;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.IntroductionLogoImageDiv {
    display: block;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.IntroductionLogoImage {
    width: 100%;
    max-width: 500px;
    /* min-width: 450px; */
    height: auto;
    margin: 0 auto;
}

.IntroductionAnnouncementDiv {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: 1200px;
    max-height: 400px;
    background-color: transparent;
}

.IntroductionAnnouncementTextDiv {
    width: 100%;
    height: 250px;
    min-height: 250px;
    background-image: url("../../../public/images/introduction_announcement_text_shape.svg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left:50px;
    background-color: transparent;
}

.IntroductionAnnouncementImage {
    width: 100%;
    max-height: 400px;
}

.IntroductionAnnouncementText {
    font-size: 1.5em;
    font-weight: 600;
    align-items: center;
    text-align: center;
    color: rgb(52, 50, 48);
    font-family: "Milky Nice";
    font-feature-settings: normal;
    font-size: 24px;
    text-transform: uppercase;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    background-color: transparent;
    padding-top: 25px;
    padding-left: 55px;
}

@media only screen and (max-width: 600px) {
    .IntroductionAnnouncementDiv {
        padding-right: 10px;
    }
    .IntroductionAnnouncementText {
        font-size: 14px;
        padding-top: 5px;
        padding-left: 0px;
        padding-right: 2px;
    }
}