.ContractMain {
    background-color: rgb(166, 194, 250);
    box-sizing: border-box;
    color: rgb(52, 50, 48);
    display: block;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 25px;
    height: 425px;
}

.ContractAddressTxt {
    color: rgb(255, 255, 255);
    display: block;
    font-family: "Milky: Nice";
    font-feature-settings: normal;
    font-size: 40px;
    font-variation-settings: normal;
    font-weight: 400;
    height: 48px;
    line-height: 48px;
    tab-size: 4;
    text-align: center;
    text-shadow: rgb(0, 0, 0);
    text-size-adjust: 100%;
    unicode-bidi: isolate;
    width: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-text-stroke-color: rgb(52, 50, 48);
    -webkit-text-stroke-width: 3px
}

.ContractAddressDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-color: rgb(52, 50, 48);
    border-style: solid;
    border-width: 5px;
    border-radius: 35px;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 25px;
    margin-top: 25px;
}

.ContractAddressInput {
    width: 100%;
    max-width: 500px;
    margin: 10px;
    padding: 10px;
    font-size: 20px;
    background-color: transparent;
    border: none;
    font-size: 20px;
    -webkit-rtl-ordering: logical;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    user-select: none;
}

.ContractAddressCopyButton {
    background-color: rgb(58, 128, 254);
    border-color: rgb(52, 50, 48);
    border-style: solid;
    border-width: 3px;
    box-sizing: border-box;
    color: rgb(52, 50, 48);
    cursor: pointer;
    font-family: "Milky Nice";
    font-size: 18px;
    font-weight: 400;
    height: 38px;
    line-height: 24px;
    padding-bottom: 4px;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 4px;
    text-align: start;
    text-size-adjust: 100%;
    transition-duration: 0.3s;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    unicode-bidi: isolate;
    width: 100px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    border-radius: 25px;
}

.ContractDexToolDiv {
    background-color: rgb(58, 128, 254);
    border-color: rgb(52, 50, 48);
    border-radius: 9999px;
    border-style: solid;
    border-width: 5px;
    box-shadow: rgb(0, 0, 0) -4px 6px 0px 0px;
    box-sizing: border-box;
    color: rgb(255, 255, 255);
    cursor: pointer;
    font-family: "Milky: Nice";
    font-feature-settings: normal;
    font-size: 16px;
    font-variation-settings: normal;
    font-weight: 400;
    height: 66px;
    line-height: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-decoration-color: rgb(255, 255, 255);
    text-decoration-line: none;
    text-decoration-style: solid;
    text-decoration-thickness: auto;
    text-size-adjust: 100%;
    transition-duration: 0.3s;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    width: 250px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

.ContractDexToolRef {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.ContractDexToolImage {
    width: 45px;
    height: 45px;
    margin: 8px;
}

.ContractDexToolText {
    font-size: 20px;
}

@media only screen and (max-width: 600px) {
    .ContractMain {
        padding-top: 25px;
        height: 400px;
        justify-content: space-between;
        padding-bottom: 25px;
    }

    .ContractAddressTxt {
        font-size: 32px;
        padding: 0px;
    }

    .ContractAddressDiv {
        padding-left: 5px;
        padding-right: 5px;
        margin-bottom: 15px;
        margin-top: 15px;
    }
}