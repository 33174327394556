.QuestionMain {
    background-color: rgb(225, 236, 247);
    box-sizing: border-box;
    color: rgb(52, 50, 48);
    display: block;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* height: 600px; */
    padding: 25px;
}

.QuestionIntroduction {
    width: 100%;
    -webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color: #343230;
    text-shadow: -6px 2px 0 #ccc;
    border: 0 solid #e5e7eb;
    box-sizing: border-box;
    font-family: Milky Nice;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    font-size: 32px;
    font-variation-settings: normal;
    font-weight: 400;
    padding: 20px;
}

.QuestionText {
    width: 100%;
    /* -webkit-text-stroke-width: 3px; */
    -webkit-text-stroke-color: #343230;
    /* text-shadow: -4px 6px 0 #000; */
    border: 0 solid #e5e7eb;
    box-sizing: border-box;
}

.QuestionDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    max-width: 1200px;
    /* max-height: 400px; */
    background-color: transparent;
}

.QuestionEachDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgb(229, 231, 235);
    border: 2px solid #ccc;
    box-sizing: border-box;
    color: rgb(52 50 48);
    font-family: "Milky Nice";
    font-size: 1rem;
    line-height: 1.5rem;
    border-radius: 25px;
    margin-bottom: 20px;
    padding: 10px;
    border-color: rgb(52, 50, 48);
    border-bottom-color: rgb(52, 50, 48);
    border-bottom-left-radius: 40px;
    border-bottom-right-radius:40px;
    border-bottom-style:solid;
    border-bottom-width: 2px;
    box-shadow: rgb(0, 0, 0) -4px 6px 0px 0px;
}

.QuestionText {
    width: 100%;
    text-align: left;
    background-color: transparent;
    text-shadow: -2px 1px 0 #bbb;
    font-size: 20px;
    border: 0 solid #e5e7eb;
    box-sizing: border-box;
    padding: 5px;
    margin: 0px;
}

.QuestionAnswer {
    width: 100%;
    text-align: left;
    background-color: transparent;
    font-style: italic;
    font-size: 18px;
}


@media only screen and (max-width: 600px) {
    .QuestionMain {
        flex-direction: column;
    }
}