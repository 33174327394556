.TokenomicsMain {
    background-color: rgb(166, 194, 250);
    box-sizing: border-box;
    color: rgb(52, 50, 48);
    display: block;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: right;
    flex-direction: column;
    padding: 50px;
}

.TokenomicsContainer {
    width: 100%;
    background-color: rgb(255, 255, 255);
    border-color: rgb(52, 50, 48);
    border-radius: 40px;
    border-style: solid;
    border-width: 2px;
    box-shadow: rgb(0, 0, 0) -4px 6px 0px 0px;
    box-sizing: border-box;
    color: rgb(52, 50, 48);
    margin: 15px
}

.TokenomicsTitle {
    border-color: rgb(229, 231, 235);
    border-style: solid;
    border-width: 0px;
    color: rgb(58, 128, 254);
    display: block;
    font-family: "Milky Nice";
    font-size: 40px;
    font-weight: 400;
    height: 48px;
    line-height: 48px;
    text-shadow: rgb(0, 0, 0) -2px 2px 0px
}

.TokenomicsContent {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.TokenomicsContentImageDiv {
    width: 50%;
    padding: 50px;
}

.TokenomicsContentImage {
    width: 100%;
    height: auto;
    max-width: 400px;
}

.TokenomicsContentDetail {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
}

.TokenomicsDetailTitle {
    color: rgb(52, 50, 48);
    font-family: "Milky Nice";
    font-size:32px;
    font-weight: 400;
}

.TokenomicsDetailList {
    justify-content: left;
    align-items: left;
}

.TokenomicsDetailChildList {
    justify-content: left;
    align-items: left;
}

.TokenomicsDetailListItemLV1 {
    text-align: left;
    color: rgb(52, 50, 48);
    font-size: 18px;
    font-weight: 550;
    padding: 5px;
}

.TokenomicsDetailListItemLV2 {
    text-align: left;
    color: rgb(52, 50, 48);
    font-size: 16px;
    font-weight: 450;
    padding: 5px;
}


@media only screen and (max-width: 600px) {
    .TokenomicsMain {
        padding: 20px;
    }

    .TokenomicsContainer{
        margin-bottom: 8px;
    }

    .TokenomicsContent {
        flex-direction: column;
    }

    .TokenomicsContentImageDiv {
        width: 100%;
    }

    .TokenomicsContentImage {
        width: 70%;
        padding: 25px
    }

    .TokenomicsContentDetail {
        width: 100%;
        padding: 0px;
    }

    .TokenomicsDetailTitle {
        width: 100%;
        font-size: 24px;
        margin: 0px;
        padding: 0px;
    }

    .TokenomicsDetailListItemLV1 {
        font-size: 16px;
        font-weight: 500;
    }

    .TokenomicsDetailListItemLV2 {
        font-size: 14px;
        font-weight: 450;
    }
}
