.RoadmapMain {
    background-color: rgb(225, 236, 247);
    box-sizing: border-box;
    color: rgb(52, 50, 48);
    display: block;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
}

.RoadmapImage {
    width: 100%;
}

.RoadmapMainContainer {
    width: 100%;
}

.RoadmapBottomContainer {
    width: 100%;
}