.SocialMain {
    background-color: rgb(225, 236, 247);
    box-sizing: border-box;
    color: rgb(52, 50, 48);
    display: block;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: right;
    flex-direction: column;
    background-image: url("../../../public/images/socical_background.svg");
    background-size: auto cover;
    background-repeat: no-repeat;
    background-position: left top;
    padding: 25px;
}

.SocialText {
    width: 100%;
    text-align: right;
    font-size: 48px;
    text-shadow: rgb(0, 0, 0) -4px 6px 0px;
    text-size-adjust: 100%;
    unicode-bidi: isolate;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-text-stroke-color: rgb(52, 50, 48);
    -webkit-text-stroke-width: 3px;
    color: rgb(255, 255, 255);
    margin: 0px;
    padding: 10px;
    margin-right: 25px;
}

.SocialJoin {
    width: 100%;
    text-align: right;
    border-color: rgb(229, 231, 235);
    border-style: solid;
    border-width: 0px;
    box-sizing: border-box;
    color: rgb(58, 128, 254);
    font-family: "Milky Nice";
    font-feature-settings: normal;
    font-size: 24px;
    font-variation-settings: normal;
    font-weight: 400;
    /* height: 24px; */
    letter-spacing: -0.6px;
    line-height: 24px;
    text-align: right;
    /* text-shadow: rgb(0, 0, 0) -4px 3px 0px; */
    text-size-adjust: 100%;
    unicode-bidi: isolate;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-text-stroke-color: rgb(52, 50, 48);
    -webkit-text-stroke-width: 1px;
    margin: 0px;
    padding: 8px;
    margin-right: 25px;
}

.SocialEmail {
    width: 100%;
    text-align: right;
    text-shadow: -1px 1px 0 #000;
    /* -webkit-text-stroke-width: 1px; */
    /* -webkit-text-stroke-color: #343230; */
    font-size: 24px;
    font-family: "Milky Nice";
    border: "0 solid #e5e7eb";
    box-sizing: border-box;
    margin: 0px;
    padding: 8px;
    margin-right: 25px;
}

.SocialPageDiv {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: right;
    margin: 0px;
    padding: 8px;
    margin-right: 25px;
}

.SocialPageButton {
    margin: 8px;
    width: 50px;
    height: 50px;
    background-color: rgb(255, 255, 255);
    border-color: rgb(52, 50, 48);
    border-radius: 12px;
    border-style: solid;
    border-width: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: rgb(0, 0, 0) -4px 6px 0px 0px;
}

.SocialPageButtonIcon {
    width: 40px;
    height: 40px;
}

.SocialBannerDiv {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: right;
    margin: 0px;
    margin-right: 25px;
}

.SocialBanner {
    height: 150px;
    margin-right: 25px;
}

